import React from 'react';
import styles from '../style';
import {discount, robot, crowdfunding} from '../assets';
import {GetStarted} from './index';
import { useRef, useState } from "react";
import { motion } from "framer-motion";
import Magnet from './Magnet';

const MagneticElements = () => {
    const [merged, setMerged] = useState(false);
  
    return (
      <div className="relative flex justify-center items-center h-screen">
           {!merged ? (
  <>
    <motion.div
      className="absolute left-1/3"
      drag
      animate={{ x: merged ? "50%" : 0, y: [0, -10, 0, -5, 0] }}
      transition={{ 
        x: { type: "spring", stiffness: 100, damping: 10 },
        y: { repeat: Infinity, repeatType: "reverse", duration: 1.5, ease: "easeInOut" }
      }}
      onDragEnd={() => setMerged(true)}
    >
      <Magnet>
        <motion.div
          className="item"
          whileHover={{ scale: 1.2, rotate: 360 }}
          whileTap={{ scale: 0.8, rotate: 360 }}
          animate={{ scale: merged ? 1.8 : 1.5 }}
          transition={{ duration: 0.5 }}
        >
          <GetStarted textb={"Us"}  />
        </motion.div>
      </Magnet>
    </motion.div>

    <motion.div
      className="absolute right-1/3"
      drag
      animate={{ x: merged ? "-50%" : 0, y: [0, -10, 0, -5, 0] }}
      transition={{ 
        x: { type: "spring", stiffness: 100, damping: 10 },
        y: { repeat: Infinity, repeatType: "reverse", duration: 1.5, ease: "easeInOut" }
      }}
      onDragEnd={() => setMerged(true)}
    >
      <Magnet>
        <motion.div
          className="item"
          whileHover={{ scale: 1.2, rotate: 360 }}
          whileTap={{ scale: 0.8, rotate: 360 }}
          animate={{ scale: merged ? 1.8 : 1.5 }}
          transition={{ duration: 0.5 }}
        >
          <GetStarted texta={"You and"}  />
        </motion.div>
      </Magnet>
    </motion.div>
  </>
) : (
  <motion.div
    className="flex justify-center items-center"
    initial={{ scale: 0 }}
    animate={{ scale: 1.5 , y: [0, -10, 0, -5, 0] }}
    transition={{ duration: 0.5 }}
  >
    <GetStarted texta={"Winning"} textb={"Together."} />
  </motion.div>
)}

      </div>
    );
  };
const Hero = () => {
    const constraintsRef = useRef(null);
  return (
<section id="home" style={{marginTop : '-10%'}} className={`flex md:flex-row flex-col items-center ${styles.paddingY}`}>
  {/* Left Section */}
  <div className={`flex-1 flex flex-col justify-start xl:px-0 sm:px-16 px-6`}>
    <div> 
      <div className="flex flex-row justify-between items-center w-full">
        <h1 className="flex-1 font-poppins font-semibold ss:text-[150px] text-[62px] text-white ss:leading-[100px] leading-[75px]">
          A little push <br className="sm:block hidden"/> {" "}
          <span className="text-gradient">To get U there.</span> {" "}
        </h1>
      </div>
    </div>
  </div>

  {/* Magnetic Elements Section - Adjusted Positioning */}
  <div className="flex-1 flex justify-center items-center sm:mt-0 mt-6 px-6 sm:px-10">
    <MagneticElements />
  </div>
</section>
  )
}

export default Hero