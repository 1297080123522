import styles from "./style";
import {Footer}
    from "./components";

import { useState} from "react";

import  HomePage  from "./HomePage";
import MainNav from "./MainNav.jsx";
import CookieConsent, { Cookies } from "react-cookie-consent";




const App = () => {


const [activeTitle, setActiveTitle] = useState('Home');
const [toggle, setToggle] = useState(false);

  return(
  <div className="deep bg-primary w-full overflow-hidden">
    
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
      <CookieConsent
  location="bottom"
  buttonText="OK"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "16px", backgroundColor: "#48CEDB" }}
  expires={150}
>
  This website uses cookies to enhance the user experience.{" "}
</CookieConsent>
        <MainNav title={activeTitle} toggle={toggle} setToggle={setToggle} />
        { (<HomePage/>)
        }
       <Footer />
      </div>
    </div>
  </div>);

};

export default App;