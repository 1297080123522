
import Spinner from "./components/Spinner";

import styles from "./style";
import {Footer}
    from "./components";

import { useState} from "react";


import MainNav from "./MainNav.jsx";
import CookieConsent, { Cookies } from "react-cookie-consent";



const PropertiesPage = () => {
  
const [activeTitle, setActiveTitle] = useState('Properties');
const [toggle, setToggle] = useState(false);
return (
  <div className="deep bg-primary w-full overflow-hidden">
    
  <div className={`${styles.paddingX} ${styles.flexCenter}`}>
    <div className={`${styles.boxWidth}`}>
            <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "16px", backgroundColor: "#48CEDB" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
      <MainNav title={activeTitle} toggle={toggle} setToggle={setToggle} />
      <section className="flex flex-col items-center text-center p-12">
    <h1 className="text-white text-6xl mb-8">Sorry, we are working on this page</h1>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
     <Spinner color='teal'/>
    </div>
  </section>
     <Footer />
    </div>
  </div>
</div>

);

}

export default PropertiesPage;