import { Billing, Business, CardDeal, Clients, CTA, Stats, Testimonials, Hero }
    from "./components";

const HomePage = () => (
    <>
       <Hero></Hero>
       <Stats />
        <Business />
        <CardDeal />
        <Testimonials />
        <CTA />
    </>
)

export default HomePage;