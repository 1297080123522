import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import SignIn from "./external_components/SignIn.tsx";
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import FinancePage from './FinancePage.jsx';
import GadgetsPage from './GadgetsPage.jsx';
import PropertiesPage from './PropertiesPage.jsx';
import ContactPage from './ContactPage.jsx';
import FashionPage from './FashionPage.jsx';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
        <Route index element={<App />} />  {/* Home page route */}
        <Route path="/signin"  element={<SignIn />} />
        <Route path="/finances"  element={<FinancePage />} />
        <Route path="/fashion"  element={<FashionPage/>} />
        <Route path="/gadgets"  element={<GadgetsPage />} />
        <Route path="/properties"  element={<PropertiesPage />} />
        <Route path="/contact"  element={<ContactPage />} />
      </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
