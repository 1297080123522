import ItemCard from "./external_components/ItemCard.tsx";
import { cars, foods, houses, watch} from "./assets/index.js";
import RecieveList from "./components/RecieveList.jsx";
import FinanceCard from "./external_components/FinanceCard.tsx";
import styles from "./style";
import {Footer}
    from "./components";

import { useState} from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import MainNav from "./MainNav.jsx";



const items = [
  {
    id: 1,
    title: "Support XP in Housing",
    date: "",
    image: houses,
    description: "We live through building houses for many",
  },
  {
    id: 2,
    title: "Support XP in Technology",
    date: "",
    image: watch,
    description: "Developing and Creation of software and hardware",
  },
  {
    id: 3,
    title: "Support XP in Farming",
    date: "",
    image: foods,
    description: "Food throughout the entire Malawi",
  }
];

const FinancePage = () => {

  const [activeTitle, setActiveTitle] = useState('Finances');
  const [toggle, setToggle] = useState(false);
  

  return (

    <div className="deep bg-primary w-full overflow-hidden">
    
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
              <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "16px", backgroundColor: "#48CEDB" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
        <MainNav title={activeTitle} toggle={toggle} setToggle={setToggle} />
        <section className="flex flex-col items-center text-center p-12">
      <h1 className="text-white text-6xl mb-8">Support XP with your Kwacha</h1>
      <h2 className="text-white text-5xl mb-6">for more power in your hands</h2>
        

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {items.map((item) => (
          <FinanceCard key={item.id} title={item.title} date={item.date} image={item.image} description={item.description} />
        ))}
      </div>

      <p style={{margin: '4%'}}><h2 className="text-white text-2xl mb-6">In exchange, recieve your kwacha back in GBP / USD accoriding to 
        the standard bank rate, methods of recieving are as follows and are between 1-10 minutes:</h2></p>

        <p>
            <RecieveList/>
        </p>

    </section>
       <Footer />
      </div>
    </div>
  </div>

   
    
  );
};

export default FinancePage;
