import { people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star  } from "../assets";

export const navLinks = [
  {
    id: "/",
    title: "Home",
  },
  {
    id: "/finances",
    title: "Finances",
  },
  {
    id: "/gadgets",
    title: "Gadgets",
  },
  {
    id: "/fashion",
    title: "Fashion",
  },
  {
    id: "/properties",
    title: "Properties",
  },
  {
    id: "/contact",
    title: "Contact",
  },


  
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Rewards",
    content:
    "Recieve finances back (in USD / GBP) or crypto assets (USDT / BTC)\n\n" +
    "Recieve finances back (in MWK) with additional commission",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Secured",
    content:
      "We take proactive steps make sure your information and transactions are secure.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Always On Time",
    content:
      "Get your returns no matter the scenario, and at your time, because your a boss with us.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "The poor say i can't get it, the rich say how can i get?",
    name: "Robert Kiyosaki",
    title: "Entrepreneur & Multi-Millionaire",
  },
  {
    id: "feedback-2",
    content:
      "If you can produce something, it will never run out",
    name: "Dr Myles Munroe",
    title: "Entrepreneur & Millionaire",

  },
  {
    id: "feedback-3",
    content:
      "I got rich when I realized that no one is coming to save me or do it for me",
    name: "Jeff Bezos",
    title: "Entrepreneur & Billionaire",
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "Active Users",
    value: "50+",
  },
  {
    id: "stats-2",
    title: "Trusted by Elyon HealthCare and XtraHealth Care",
    value: "2+",
  },
  {
    id: "stats-3",
    title: "Transactions",
    value: "£6000+ ",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Content",
        link: "https://www.hoobank.com/content/",
      },
      {
        name: "How it Works",
        link: "https://www.hoobank.com/how-it-works/",
      },
      
      {
        name: "Terms & Services",
        link: "https://www.hoobank.com/terms-and-services/",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Help Center",
        link: "https://www.hoobank.com/help-center/",
      },
      {
        name: "Partners",
        link: "https://www.hoobank.com/partners/",
      },
      {
        name: "Suggestions",
        link: "https://www.hoobank.com/suggestions/",
      },
    ],
  },
  {
    title: "Contact Us",
    links: [
      {
        name: "+44 7506 369609",
        link: "",
      },
      {
        name: "conrad@itsxtrapush.com",
        link: "",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];