import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Footer } from "./components";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "./style";
import CookieConsent, { Cookies } from "react-cookie-consent";
import MainNav from "./MainNav.jsx";


const ContactPage = () => {
    const isSmallScreen = useMediaQuery("(max-width:800px)");
    const formWidth = isSmallScreen ? "100%" : "60%";

    const [activeTitle, setActiveTitle] = useState('Contact');
const [toggle, setToggle] = useState(false);


    useEffect(() => {
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 0);
      }, [])

  return (
    <div className="deep bg-primary w-full overflow-hidden">
    
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
              <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "16px", backgroundColor: "#48CEDB" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
        <MainNav title={activeTitle} toggle={toggle} setToggle={setToggle} />
        <section className="flex flex-col items-center text-center p-12">
      <h1 className="text-white text-6xl mb-8">Got a Challenge?</h1>
      <h2 className="text-white text-5xl mb-6">We are here to help</h2>
      <>
        <Box
            component="form"
            sx={{
              width: formWidth, // Responsive width
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              padding: 3,
              backgroundColor: "#051323",
              borderRadius: 2,
            }}
            noValidate
            autoComplete="off"
        >
         <TextField
  id="name"
  label="Name"
  placeholder="John Doe"
  multiline
  maxRows={4}
  fullWidth
  sx={{
    "& .MuiInputBase-input": { color: "white" }, // Corrected input text color
    "& .MuiInputLabel-root": { color: "white" }, // Ensures label is white
    "& .MuiInputLabel-root.Mui-focused": { color: "white" }, // Ensures label stays white when focused
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderColor: "white" },
      "&:hover fieldset": { borderColor: "lightgray" },
    },
    "& .MuiInputBase-input::placeholder": {
      color: "white", // Ensures placeholder text is white
      opacity: 1, // Fix for browsers that reduce opacity
    },
  }}
/>
<TextField
  id="subject"
  label="Subject"
  placeholder="Enquiry on Purchase"
  multiline
  maxRows={4}
  fullWidth
  sx={{
    "& .MuiInputBase-input": { color: "white" }, // Ensures input text is white
    "& .MuiInputLabel-root": { color: "white" }, // Ensures label is white
    "& .MuiInputLabel-root.Mui-focused": { color: "white" }, // Keeps label white on focus
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderColor: "white" },
      "&:hover fieldset": { borderColor: "lightgray" },
    },
    "& .MuiInputBase-input::placeholder": {
      color: "white",
      opacity: 1, // Ensures placeholder is fully visible
    },
  }}
/>

<TextField
  id="email"
  label="Email"
  placeholder="johndoe@gmail.com"
  multiline
  maxRows={4}
  fullWidth
  sx={{

    "& .MuiInputBase-input": { color: "white" },
    "& .MuiInputLabel-root": { color: "white" },
    "& .MuiInputLabel-root.Mui-focused": { color: "white" },
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderColor: "white" },
      "&:hover fieldset": { borderColor: "lightgray" },
    },
    "& .MuiInputBase-input::placeholder": {
      color: "white",
      opacity: 1,
    },
  }}
/>

<TextField
  id="message"
  label="Message"
  placeholder="I have this issue..."
  multiline
  rows={4}
  fullWidth
  sx={{

    "& .MuiInputBase-input": { color: "white" },
    "& .MuiInputLabel-root": { color: "white" },
    "& .MuiInputLabel-root.Mui-focused": { color: "white" },
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderColor: "white" },
      "&:hover fieldset": { borderColor: "lightgray" },
    },
    "& .MuiInputBase-input::placeholder": {
      color: "white",
      opacity: 1,
    },
  }}
/>

          <Button
            variant="contained"
            sx={{
              width: "100%",
              backgroundColor: "#48CEDB",
              color: "#white",
              "&:hover": { backgroundColor: "darkblue" },
            }}
          >
            <Link to="/signin" style={{ textDecoration: "none", color: "inherit" }}>
              Submit
            </Link>
          </Button>
        </Box>
      </>
    </section>
       <Footer />
      </div>
    </div>
  </div>
   
  );
}

export default ContactPage;
