import styles from "./style";



import  HomePage  from "./HomePage";
import GadgetsPage from "./GadgetsPage.jsx";
import PropertiesPage from "./PropertiesPage.jsx";
import FinancePage from "./FinancePage.jsx";
import InfoPage from "./PropertiesPage.jsx";
import ContactPage from "./ContactPage.jsx";
import { close, logo, menu } from './assets';
import SVGComponent from "./components/Logo.jsx";
import { navLinks } from './constants';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import FashionPage from "./FashionPage.jsx";



const MainNav = ({title, toggle, setToggle}) => {
    return (
        <nav className='w-full flex py-6 justify-between items-center navbar'>
        <SVGComponent />
        <ul className='list-none sm:flex hidden justify-end items-center flxe-1'>
          {navLinks.map((el, index) => {
            const isActive = title === el.title; // Check if the link is active
  
            return (
              <motion.div
                key={el.id}
                whileHover={{ scale: 1.15 }} // Scale on hover
                animate={{ scale: isActive ? 1.25 : 1 }} // Scale if active
              >
                <li
                  className={`current-page font-poppins font-normal cursor-pointer text-[16px] 
                    ${index === navLinks.length - 1 ? 'mr-0' : 'mr-10'} 
                    ${isActive ? 'text-teal-400' : 'text-white'}`} // Optional: Add a style when active
                 // Set active title on click
                ><Link to={el.id}>
                {el.title}</Link>
                </li>
              </motion.div>
            );
          })}
          <Button  style={{ marginLeft: '20px' }} variant="contained"><Link to="/signin">Sign In</Link></Button>
        </ul>
  
        <div className='sm:hidden flex flex-1 justify-end items-center'>
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] h-[28px] object-contain"
            onClick={() => setToggle((prev) => !prev)}
          />
  
          <div className={`${toggle ? 'flex' : 'hidden'} p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}>
            <ul className='list-none flex flex-col justify-end items-center flex-1'>
              {navLinks.map((el, index) => {
                return (
                  <li key={el.id} className={`font-poppins font-normal cursor-pointer text-[16px] ${index === navLinks.length - 1 ? 'mr-0' : 'mb-4'} text-white`}>
                    <Link to={el.id}>
                     {el.title}</Link>
                  </li>
                );
              })}
              <Button  style={{ marginLeft: '20px', marginTop: '20px'}} variant="contained"><Link to="/signin">Sign In</Link></Button>
            </ul>
          </div>
        </div>
      </nav>
    )
}
export default MainNav;