import ItemCard from "./external_components/ItemCard.tsx";
import { macbookm4, iphone16, iphone16max, s24, s24ul, s25, s25ul } from "./assets/index.js";
import styles from "./style";
import {Footer}
    from "./components";

import { useState} from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";

import MainNav from "./MainNav.jsx";




const items = [
  {
    id: 1,
    title: "MacBook Pro M4",
    date: "January 20, 2024",
    image: macbookm4,
    description: "A powerful laptop for developers and creatives.",
    price: "£1400"
  },
  {
    id: 2,
    title: "iPhone 16 Pro Max",
    date: "February 5, 2025",
    image: iphone16max,
    description: "The latest smartphone with an advanced camera system.",
    price: "£950"
  },
  {
    id: 3,
    title: "iPhone 16",
    date: "March 10, 2025",
    image: iphone16,
    description: "Small Size, Intelligent Smartphone.",
    price: "£700"
  },
  {
    id: 4,
    title: "Samsung S25 Ultra",
    date: "March 3, 2025",
    image: s25ul,
    description: "Camera at its peak",
    price: "£900"
  },
  {
    id: 5,
    title: "Samsung S25",
    date: "March 3, 2025",
    image: s25,
    description: "Camera at its peak in small hands",
    price: "£700"
  },
  {
    id: 6,
    title: "Samsung S24",
    date: "March 1, 2024",
    image: s24,
    description: "Camera at its peak in small hands",
    price: "£600"
  },
  {
    id: 7,
    title: "Samsung S24 Ultra",
    date: "March 1, 2024",
    image: s24ul,
    description: "Beyond the limits",
    price: "£800"
  },
];

const GadgetsPage = () => {
  const [activeTitle, setActiveTitle] = useState('Gadgets');
  const [toggle, setToggle] = useState(false);

  return (

    <div className="deep bg-primary w-full overflow-hidden">
    
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
              <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "16px", backgroundColor: "#48CEDB" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
        <MainNav title={activeTitle} toggle={toggle} setToggle={setToggle} />
        <section className="flex flex-col items-center text-center p-12">
      <h1 className="text-white text-6xl mb-8">The best tech in the best hands</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {items.map((item) => (
          <ItemCard key={item.id} title={item.title} date={item.date} image={item.image} description={item.description} price={item.price} />
        ))}
      </div>
    </section>
       <Footer />
      </div>
    </div>
  </div>
    
  );
};

export default GadgetsPage;
